import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'get-update',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './update.component.html',
  styleUrls: ['./update.component.scss']
})
export class UpdateComponent {
  public reload(): void {
    window.location.reload();
  }
}
